import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class NavbarV3 extends Component {

	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imgattr = 'logo'
		let anchor = '#'
		const currentUrl = window.location.pathname;
		return (
			<div>
				<header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-2">
					<div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
						<div className="container">
							<div className="row">
								<div className="col">
									<div className="site-logo-wrap">
										<div className="site-logo go-top" style={{ width: '160px' }}>
											<Link to="/"><img src={publicUrl + "assets/img/interboro.png"} alt="Logo" /></Link>
										</div>
										<div style={{    padding: '0px 0px 0px 20px',
    color: 'white',
    margin: 'auto 0'}}>
										{currentUrl === '/' ? '' : 'An MWBE Company'}
										</div>
									</div>
								</div>
								<div className="col header-menu-column menu-color-white">
									<div className="header-menu d-none d-xl-block go-top">
										<nav>
											<div className="ltn__main-menu" style={{ float: 'right' }}>
												<ul>
													<li ><Link to="/">Home</Link>

													</li>
													<li ><Link to="/about">About Us</Link>

													</li>
													<li><Link to="/about#serviceSection">Services</Link></li>

													<li><Link to="/projects">Projects</Link>

													</li>

													<li><Link to="/contact">Contact Us</Link></li>

												</ul>
											</div>
										</nav>
									</div>
								</div>
								<div className="col--- ltn__header-options ltn__header-options-2 ">

									<div className="mobile-menu-toggle d-xl-none">
										<a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
											<svg viewBox="0 0 800 600">
												<path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
												<path d="M300,320 L540,320" id="middle" />
												<path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
											</svg>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

				<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
					<div className="ltn__utilize-menu-inner ltn__scrollbar">
						<div className="ltn__utilize-menu-head">
							<div className="site-logo">
								<Link to="/"><img src={publicUrl + "assets/img/interboro.png"} alt="Logo" /></Link>
							</div>
							<button className="ltn__utilize-close">×</button>
						</div>
						{/* <div className="ltn__utilize-menu-search-form">
							<form action={"#"}>
								<input type="text" placeholder="Search..." />
								<button><i className="fas fa-search" /></button>
							</form>
						</div> */}
						<div className="ltn__utilize-menu">
							<ul>
								<li><a href="#">Home</a>

								</li>
								<li><Link to="/about">About Us</Link>

								</li>
								<li><Link to="/about#serviceSection">Services</Link></li>
								<li><Link to="/projects">Projects</Link></li>


								<li><Link to="/contact">Contact US</Link></li>
							</ul>
						</div>
						{/* <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
					<ul>
						<li>
						<Link to="/my-account" title="My Account">
							<span className="utilize-btn-icon">
							<i className="far fa-user" />
							</span>
							My Account
						</Link>
						</li>
						<li>
						<Link to="/wishlist" title="Wishlist">
							<span className="utilize-btn-icon">
							<i className="far fa-heart" />
							<sup>3</sup>
							</span>
							Wishlist
						</Link>
						</li>
						<li>
						<Link to="/cart" title="Shoping Cart">
							<span className="utilize-btn-icon">
							<i className="fas fa-shopping-cart" />
							<sup>5</sup>
							</span>
							Shoping Cart
						</Link>
						</li>
					</ul>
					</div> */}
						{/* <div className="ltn__social-media-2">
					<ul>
						<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
						<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
						<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
					</div> */}
					</div>
				</div>
			</div>
		)
	}
}


export default NavbarV3