import React, { useState, useEffect } from "react";
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import AboutV1 from './section-components/about-v1';
import Features from './section-components/features-v1';
import Team from './section-components/team-v1';
import Testimonial from './section-components/testimonial-v1';
import BlogSlider from './blog-components/blog-slider-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import TopBar from './global-components/topbar';
import Footer from './global-components/footer';
import Category from './section-components/category-v1';
import serviceData from "../data/services.json";
import { useHistory } from "react-router-dom";


const About_v1 = () => {

    const [data, setData] = useState(null);

    let publicUrl = process.env.PUBLIC_URL + '/'
    let imagealt = 'image'

    useEffect(() => {
        // In this case, the JSON data is already imported statically
        setData(serviceData);

    }, []);

    const history = useHistory();

    const navigateToSection = (sectionId) => {
      history.push(`/about#${sectionId}`); // Navigate to a new route with a hash
    };
    return (
        <div>
            <TopBar/> 
            <Navbar />
            <PageHeader headertitle="About Us" />
            {data && <AboutV1 />}

            {data && <Category data={data}/>}
            {/* <Features  customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/>
        <Team />
        <Testimonial />
        <BlogSlider />
        <CallToActionV1 /> */}
            <Footer />
        </div>
    )
}

export default About_v1

