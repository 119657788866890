
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import portfolioData from "../data/projects.json";

import React, { useState, useEffect } from "react";
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import ServiceDetails from './section-components/service-details';
import serviceInfo from "../data/serviceDetails.json";
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const Product_Details = ({title}) => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);

    // Get the last segment (or part of the URL after the last slash)
    const lastSegment = pathSegments[pathSegments.length - 1];

    const filterData = portfolioData.filter((item) => item.filter == lastSegment);

    return <div>
        <Navbar />
        <PageHeader headertitle="Product Details" customclass="mb-0" />
        {/* <ProductSlider /> */}
        <ProductDetails projectData={filterData[0]}/>
        {/* <CallToActionV1 /> */}
        <Footer />
    </div>
}

export default Product_Details

