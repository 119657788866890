import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch, Link } from "react-router-dom";
import ScrollToSection from "./scrollToSection";

import HomeV5 from './components/home-v5';
//import App2 from './components/section-components/apartment-v2';
import About from './components/about';
// import Services from './components/service';

//import ServiceDetails from './components/service-details';
import ProjectDetails from './components/product-details';
//import PortfolioV2 from './components/portfolio-v2';

// import ComingSoon from './components/coming-soon';
// import Error from './components/404';
//import Location from './components/location';

import Projects from './components/projects'
import Contact from './components/contact';


class Root extends Component {
    render() {
        return (
            <Router basename="/">

                <div>
                    <Switch>
                        <Route exact path="/" component={HomeV5} />
                        <Route exact path="/contact" component={Contact} />
                        <Route path="/about" component={About} />
                      
                     
                        <Route path="/projects" component={Projects} />
                        
                        {/* <Route path="/services" component={Services} /> */}
                        <Route path="/project-details" component={ProjectDetails} />
                        {/* blog
                        <Route path="/blog-grid" component={BlogGrid} />
                        <Route path="/blog-left-sidebar" component={BlogLeftSidebar} />
                        <Route path="/blog-right-sidebar" component={BlogRightSidebar} />
                        <Route path="/blog" component={Blog} /> */}


                        {/* <Route path="/service-details" component={ BlogDetails } />
                        <Route path="/contact" component={ Contact } />
                        <Route path="/cart" component={ Cart } />
                        <Route path="/checkout" component={ Checkout } />
                        <Route path="/my-account" component={ MyAccount } />
                        <Route path="/login" component={ Login } />
                        <Route path="/register" component={ Register } />
                        <Route path="/add-listing" component={ AddListing } />
                        <Route path="/wishlist" component={ Wishlist } />
                        <Route path="/order-tracking" component={ OrderTracking } />
                        <Route path="/history" component={ History } /> */}
                    </Switch>
                </div>
                <ScrollToSection />
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('Interboro'));
