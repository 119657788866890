import React from 'react';
import Navbar from './global-components/navbar-v4';
import PageHeader from './global-components/page-header';
import AboutV1 from './section-components/about-v1';
import TopBar from './global-components/topbar';
import Footer from './global-components/footer';
import Category from './section-components/category-v1';
import ApartmentV1 from './section-components/apartment-v1';

const Projects = () => {
    return <div>
        <TopBar/>
        <Navbar />
        <PageHeader headertitle="Our Projects" />
       <ApartmentV1/>

      
        {/* <Features  customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/>
        <Team />
        <Testimonial />
        <BlogSlider />
        <CallToActionV1 /> */}
        <Footer />
    </div>
}

export default Projects

