import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

// class ShopDetails extends Component {
const ShopDetails = ({ projectData }) => {
	// render() {

	let publicUrl = process.env.PUBLIC_URL + '/'

	return <div className="ltn__shop-details-area pb-10">
		<div className="container">
			<div className="row">
				<div className="col-lg-8 col-md-12">
					<div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
						{/* <div className="ltn__blog-meta">
							<ul>

								<li className="ltn__blog-date">
									<i className="far fa-calendar-alt" />May 19, 2021
								</li>

							</ul>
						</div> */}
						<h1>{projectData.title}</h1>
						{/* <label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> Belmont Gardens, Chicago</label> */}
						<h4 className="title-2">Description</h4>
						<p>{projectData.fullDescription}</p>

						{/* <h4 className="title-2">Property Detail</h4> */}
						{/* <div className="property-detail-info-list section-bg-1 clearfix mb-60">
							<ul>
								<li><label>Property ID:</label> <span>HZ29</span></li>
								<li><label>Home Area: </label> <span>120 sqft</span></li>
								<li><label>Rooms:</label> <span>7</span></li>
								<li><label>Baths:</label> <span>2</span></li>
								<li><label>Year built:</label> <span>1992</span></li>
							</ul>
							<ul>
								<li><label>Lot Area:</label> <span>HZ29 </span></li>
								<li><label>Lot dimensions:</label> <span>120 sqft</span></li>
								<li><label>Beds:</label> <span>7</span></li>
								<li><label>Price:</label> <span>2</span></li>
								<li><label>Property Status:</label> <span>For Sale</span></li>
							</ul>
						</div> */}
						{/* <h4 className="title-2">Facts and Features</h4> */}
						{/* <div className="property-detail-feature-list clearfix mb-45">
							<ul>
								<li>
									<div className="property-detail-feature-list-item">
										<i className="flaticon-double-bed" />
										<div>
											<h6>Living Room</h6>
											<small>20 x 16 sq feet</small>
										</div>
									</div>
								</li>
								<li>
									<div className="property-detail-feature-list-item">
										<i className="flaticon-double-bed" />
										<div>
											<h6>Garage</h6>
											<small>20 x 16 sq feet</small>
										</div>
									</div>
								</li>
								<li>
									<div className="property-detail-feature-list-item">
										<i className="flaticon-double-bed" />
										<div>
											<h6>Dining Area</h6>
											<small>20 x 16 sq feet</small>
										</div>
									</div>
								</li>
								<li>
									<div className="property-detail-feature-list-item">
										<i className="flaticon-double-bed" />
										<div>
											<h6>Bedroom</h6>
											<small>20 x 16 sq feet</small>
										</div>
									</div>
								</li>
								<li>
									<div className="property-detail-feature-list-item">
										<i className="flaticon-double-bed" />
										<div>
											<h6>Bathroom</h6>
											<small>20 x 16 sq feet</small>
										</div>
									</div>
								</li>
								<li>
									<div className="property-detail-feature-list-item">
										<i className="flaticon-double-bed" />
										<div>
											<h6>Gym Area</h6>
											<small>20 x 16 sq feet</small>
										</div>
									</div>
								</li>
								<li>
									<div className="property-detail-feature-list-item">
										<i className="flaticon-double-bed" />
										<div>
											<h6>Garden</h6>
											<small>20 x 16 sq feet</small>
										</div>
									</div>
								</li>
								<li>
									<div className="property-detail-feature-list-item">
										<i className="flaticon-double-bed" />
										<div>
											<h6>Parking</h6>
											<small>20 x 16 sq feet</small>
										</div>
									</div>
								</li>
							</ul>
						</div> */}
						{/* <h4 className="title-2">From Our Gallery</h4>
						<div className="ltn__property-details-gallery mb-30">
							<div className="row">
								<div className="col-md-6">
									<a href={publicUrl + "assets/img/others/led-install.jpg"} data-rel="lightcase:myCollection">
										<img className="mb-30" src={publicUrl + "assets/img/others/led-install.jpg"} alt="Image" />
									</a>
									<a href={publicUrl + "assets/img/others/led-install.jpg"} data-rel="lightcase:myCollection">
										<img className="mb-30" src={publicUrl + "assets/img/others/led-install.jpg"} alt="Image" />
									</a>
								</div>
								<div className="col-md-12">
									<a href={publicUrl + "assets/img/others/led-install.jpg"} data-rel="lightcase:myCollection">
										<img className="mb-30" src={publicUrl + "assets/img/others/led-install.jpg"} alt="Image" />
									</a>
								</div>
							</div>
						</div> */}
						{/* <h4 className="title-2 mb-10">Amenities</h4> */}
						{/* <div className="property-details-amenities mb-60">
							<div className="row">
								<div className="col-lg-4 col-md-6">
									<div className="ltn__menu-widget">
										<ul>
											<li>
												<label className="checkbox-item">Air Conditioning
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Gym
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Microwave
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Swimming Pool
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">WiFi
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div className="ltn__menu-widget">
										<ul>
											<li>
												<label className="checkbox-item">Barbeque
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Recreation
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Microwave
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Basketball Cout
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Fireplace
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div className="ltn__menu-widget">
										<ul>
											<li>
												<label className="checkbox-item">Refrigerator
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Window Coverings
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Washer
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">24x7 Security
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
											<li>
												<label className="checkbox-item">Indoor Game
													<input type="checkbox" defaultChecked="checked" />
													<span className="checkmark" />
												</label>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div> */}
						{/* <h4 className="title-2">Location</h4>
						<div className="property-details-google-map mb-60">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9334.271551495209!2d-73.97198251485975!3d40.668170674982946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b0456b5a2e7%3A0x68bdf865dda0b669!2sBrooklyn%20Botanic%20Garden%20Shop!5e0!3m2!1sen!2sbd!4v1590597267201!5m2!1sen!2sbd" width="100%" height="100%" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
						</div> */}


						{/* APARTMENTS PLAN AREA END */}
						{/* <h4 className="title-2">Property Video</h4> */}
						{projectData.vid &&
							<div className="ltn__property-details-gallery mb-30">
								<div className="row">
									<div className="col-lg-6 col-md-12">

										<a href={publicUrl + `assets/img/others/${projectData.img}`} data-rel="lightcase:myCollection">
											<img className="mb-30" src={publicUrl + `assets/img/others/${projectData.img}`} alt="Image" />
										</a>

									</div>
									<div className="col-lg-6 col-md-12">
										{projectData.img1 &&

											<a href={publicUrl + `assets/img/others/${projectData.img1}`} data-rel="lightcase:myCollection">
												<img className="mb-30" src={publicUrl + `assets/img/others/${projectData.img1}`} alt="Image" />
											</a>

										}
									</div>
								</div>
							</div>
						}
					</div>
				</div>

				<div className="col-lg-4 col-md-12 mt-30">
					{projectData.vid ?
						<div className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60" data-bs-bg={publicUrl + "assets/img/others/5.jpg"}>
							<video autoPlay muted playsInline loop id="myVideo">
								<source src={publicUrl + `assets/img/others/${projectData.vid}`} type="video/mp4" />
							</video>
						</div>
						:
						<a href={publicUrl + `assets/img/others/${projectData.img}`} data-rel="lightcase:myCollection">
							<img className="mb-30" src={publicUrl + `assets/img/others/${projectData.img}`} alt="Image" />
						</a>
					}
				</div>

			</div>
		</div>
	</div>

}

export default ShopDetails